import { WorkflowValidation } from '@/lib/form/formTemplateSetting/formTemplateSetting.interface';
import {
  workflowButtonMap,
  workflowStepMap,
} from '@/lib/form/formTemplateSetting/formTemplateSettingSlice';
import { StepRecord } from '@/lib/submissions/submissions.interface';
import {
  SubmissionFormDetail,
  SubmissionFormPageData,
} from '@/lib/submissionTemplates/submissionTemplateSetting.interface';
import { submissionTemplateSettingApi } from '@/lib/submissionTemplates/submissionTemplateSettingAPI';
import {
  DeviceType,
  Orientation,
} from '@app/form/template/[formTemplateId]/_component/Preview/preview.interface';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { utils } from '@gobuid/flow-template';
import { submissionShareLinkApi } from '@/lib/submissionShareLink/submissionShareLinkAPI';

const { validateFlowData } = utils;

export const workflowStepToStepRecords = (nodes: any[]): StepRecord[] => {
  return nodes
    .filter((node) => node.type === 'step')
    .map((step) => ({
      stepId: step.id,
      submissionProcessId: parseInt(uuidv4().replace(/-/g, '').slice(0, 8), 16),
      version: null,
      submitter: null,
      submittedAt: null,
      submitActionId: null,
      isRevokedStep: false,
    }));
};
interface InitialState {
  pages: SubmissionFormPageData[] | null;
  photos: any[] | null;
  files: any[] | null;
  preview: {
    device: DeviceType;
    orientation: Orientation;
    isEditMode: boolean;
    copyPages: SubmissionFormPageData[] | null;
  };
  workflow: {
    nodes: any[] | null;
    edges: any[] | null;
    steps: any[] | null;
    teams: any[] | null;
    buttons: any[] | null;
    validation: WorkflowValidation;
  };
  workflowSubForm: {
    nodes: any[] | null;
    edges: any[] | null;
    steps: any[] | null;
    teams: any[] | null;
    buttons: any[] | null;
    validation: WorkflowValidation;
  };
  formDetail: SubmissionFormDetail | null;
  formDetailSubForm: SubmissionFormDetail | null;
  isFormDetailReady: boolean;
  view: {
    isStepInfoVisible: boolean;
    stepRecords: StepRecord[] | null;
  };
  viewSubForm: {
    isStepInfoVisible: boolean;
    stepRecords: StepRecord[] | null;
  };
}

const initialState: InitialState = {
  pages: [],
  photos: [],
  files: [],
  preview: {
    device: 'phone',
    orientation: 'portrait',
    isEditMode: false,
    copyPages: [],
  },
  workflow: {
    nodes: [],
    edges: [],
    steps: [],
    teams: [],
    buttons: [],
    validation: {
      isAllSendEmail: false,
      isAllButtonClose: false,
      isAllBackToPreviousClose: false,
      isAllCreateNewVersionClose: false,
      isAllClose: false,
    },
  },
  workflowSubForm: {
    nodes: [],
    edges: [],
    steps: [],
    teams: [],
    buttons: [],
    validation: {
      isAllSendEmail: false,
      isAllButtonClose: false,
      isAllBackToPreviousClose: false,
      isAllCreateNewVersionClose: false,
      isAllClose: false,
    },
  },
  formDetail: {
    name: '',
    folderName: '',
    folderId: null,
    referenceNumberPrefix: '',
    referenceNumberSuffix: '',
    referenceNumberStartsFrom: '',
    validTimeStepId: null,
    validTimeDays: null,
  },
  formDetailSubForm: {
    name: '',
    folderName: '',
    folderId: null,
    referenceNumberPrefix: '',
    referenceNumberSuffix: '',
    referenceNumberStartsFrom: '',
    validTimeStepId: null,
    validTimeDays: null,
  },
  isFormDetailReady: false,
  view: {
    isStepInfoVisible: false,
    stepRecords: null,
  },
  viewSubForm: {
    isStepInfoVisible: false,
    stepRecords: null,
  },
};

const submissionTemplateSettingSlice = createSlice({
  name: 'submissionTemplateSetting',
  initialState,
  reducers: {
    setFormDetail: (state, action) => {
      state.formDetail = action.payload;
    },
    setFormDetailReady: (state, action) => {
      state.isFormDetailReady = action.payload;
    },
    setSubmissionPages: (state, action) => {
      state.pages = action.payload;
      state.preview.copyPages = action.payload;
    },
    setIsStepInfoVisible: (state, action) => {
      state.view.isStepInfoVisible = action.payload;
    },
    setSubmissionTemplatePreviewDevice: (state, action) => {
      state.preview.device = action.payload;
    },
    setSubmissionTemplatePreviewOrientation: (state, action) => {
      state.preview.orientation = action.payload;
    },
    setSubmissionTemplatePreviewIsEditMode: (state, action) => {
      state.preview.isEditMode = action.payload;
    },
    toggleIsStepInfoVisible: (state, _) => {
      state.view.isStepInfoVisible = !state.view.isStepInfoVisible;
    },
    setPages: (state, action) => {
      state.pages = action.payload;
      state.preview.copyPages = action.payload;
    },
    setWorkflowValidation: (state, action) => {
      state.workflow.validation = action.payload;
    },
    setWorkflowNodes: (state, action) => {
      state.workflow.nodes = action.payload;
      state.workflow.steps = workflowStepMap(action.payload);
      state.workflow.buttons = workflowButtonMap(action.payload);
    },
    setWorkflowEdges: (state, action) => {
      state.workflow.edges = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      submissionTemplateSettingApi.endpoints.getSubmissionFormBody
        .matchFulfilled,
      (state, action) => {
        state.files = action.payload.files ?? [];
        state.photos = action.payload.photos ?? [];
        state.pages = action.payload.pages ?? [];
        state.preview.copyPages = action.payload.pages ?? [];
      },
    );
    builder.addMatcher(
      submissionTemplateSettingApi.endpoints.getSubmissionWorkflow
        .matchFulfilled,
      (state, action) => {
        const steps = workflowStepMap(action.payload.nodes);
        const nodes = action.payload.nodes ?? [];
        const edges = action.payload.edges ?? [];

        state.workflow.nodes = nodes;
        state.workflow.edges = edges;
        state.workflow.steps = steps;
        state.workflow.teams = action.payload.teams;
        state.workflow.buttons = workflowButtonMap(nodes);

        state.view.stepRecords = workflowStepToStepRecords(steps);
        state.workflow.validation = validateFlowData(nodes, edges);
      },
    );
    builder.addMatcher(
      submissionTemplateSettingApi.endpoints.getSubmissionWorkflowSubForm
        .matchFulfilled,
      (state, action) => {
        const steps = workflowStepMap(action.payload.nodes);
        const nodes = action.payload.nodes ?? [];
        const edges = action.payload.edges ?? [];

        state.workflowSubForm.nodes = nodes;
        state.workflowSubForm.edges = edges;
        state.workflowSubForm.steps = steps;
        state.workflowSubForm.buttons = workflowButtonMap(nodes);

        state.viewSubForm.stepRecords = workflowStepToStepRecords(steps);
        state.workflowSubForm.validation = validateFlowData(nodes, edges);
      },
    );
    // 將share link workflow的teams設定到workflow的teams
    builder.addMatcher(
      submissionShareLinkApi.endpoints.getShareLinkFormWorkflow.matchFulfilled,
      (state, action) => {
        state.workflow.teams = action.payload.teams;
      },
    );
    builder.addMatcher(
      submissionTemplateSettingApi.endpoints.getSubmissionFormDetail
        .matchFulfilled,
      (state, action) => {
        state.formDetail = action.payload;
      },
    );
    builder.addMatcher(
      submissionTemplateSettingApi.endpoints.getSubmissionFormDetailSubForm
        .matchFulfilled,
      (state, action) => {
        state.formDetailSubForm = action.payload;
      },
    );
  },
});

export const {
  setFormDetail,
  setFormDetailReady,
  setSubmissionPages,
  setIsStepInfoVisible,
  setSubmissionTemplatePreviewDevice,
  setSubmissionTemplatePreviewOrientation,
  setSubmissionTemplatePreviewIsEditMode,
  toggleIsStepInfoVisible,
  setWorkflowNodes,
  setWorkflowEdges,
  setWorkflowValidation,
  setPages,
} = submissionTemplateSettingSlice.actions;
export default submissionTemplateSettingSlice.reducer;
