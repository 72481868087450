import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosResponse } from 'axios';

import {
  ShareLinkPrerequisitesParams,
  ShareLinkProgressParams,
  ShareLinkSaveStepRequest,
  ShareLinkSubmissionDetail,
  ShareLinkSubmissionDetailParams,
  ShareLinkSubmissionPrerequisites,
  ShareLinkSubmissionProcessDetailParams,
  ShareLinkSubmissionProgress,
  ShareLinkSubmitStepRequest,
  ShareLinkVerifyParams,
} from '@/lib/submissionShareLink/submissionShareLink.interface';
import { submissionApi } from '@/lib/submissions/submissionsAPI';
import { axiosBaseQuery } from '../axiosBaseQuery';

export const submissionShareLinkApi = createApi({
  reducerPath: 'submissionShareLinkApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getShareLinkStep: builder.query<any, ShareLinkVerifyParams>({
      query: (params) => ({
        url: `/api/SubmissionShareLink/${params.submissionId}`,
        method: 'GET',
        params: {
          submissionId: params.submissionId,
        },
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    verifyShareLink: builder.query<any, ShareLinkVerifyParams>({
      query: (params) => ({
        url: `/api/SubmissionShareLink/Verify/${params.submissionId}`,
        method: 'POST',
        data: {
          submissionId: params.submissionId,
        },
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    getShareLinkSubmissionDetail: builder.query<
      ShareLinkSubmissionDetail,
      ShareLinkSubmissionDetailParams
    >({
      query: (params) => ({
        url: `/api/SubmissionShareLink/SubmissionDetail/${params.submissionId}`,
        method: 'GET',
        params: {
          submissionId: params.submissionId,
        },
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    getShareLinkSubmissionProcessDetail: builder.query<
      any,
      ShareLinkSubmissionProcessDetailParams
    >({
      query: (params) => ({
        url: `/api/SubmissionShareLink/SubmissionProcessDetail/${params.submissionId}`,
        method: 'GET',
        params: {
          submissionId: params.submissionId,
        },
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    getShareLinkSubmissionProgress: builder.query<
      ShareLinkSubmissionProgress,
      ShareLinkProgressParams
    >({
      query: (params) => ({
        url: `/api/SubmissionShareLink/SubmissionProgress/${params.submissionId}`,
        method: 'GET',
        params: {
          submissionId: params.submissionId,
        },
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    saveShareLinkSubmissionStep: builder.mutation<
      any,
      ShareLinkSaveStepRequest
    >({
      query: (data) => ({
        url: `/api/SubmissionShareLink/SaveStep`,
        method: 'PUT',
        data,
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    submitShareLinkSubmissionStep: builder.mutation<
      any,
      ShareLinkSubmitStepRequest
    >({
      query: (data) => ({
        url: `/api/SubmissionShareLink/SubmitStep`,
        method: 'POST',
        data,
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          submissionApi.util.invalidateTags([
            { type: 'filledSubmission' as const },
            { type: 'submissionProgress' as const },
            { type: 'filledSubmissionSubForm' as const },
          ]),
        );
      },
    }),
    getShareLinkFormWorkflow: builder.query<any, any>({
      query: (params) => ({
        url: `/api/SubmissionShareLink/FormWorkflow/${params.submissionId}`,
        method: 'GET',
        params: {
          submissionId: params.submissionId,
        },
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    getShareLinkSubmissionPrerequisites: builder.query<
      ShareLinkSubmissionPrerequisites,
      ShareLinkPrerequisitesParams
    >({
      query: (params) => ({
        url: `/api/SubmissionShareLink/SubmissionPrerequisites/${params.submissionId}`,
        method: 'GET',
        params: {
          submissionId: params.submissionId,
        },
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
  }),
});

export const {
  useGetShareLinkStepQuery,
  useVerifyShareLinkQuery,
  useGetShareLinkSubmissionDetailQuery,
  useGetShareLinkSubmissionProcessDetailQuery,
  useGetShareLinkSubmissionProgressQuery,
  useSaveShareLinkSubmissionStepMutation,
  useSubmitShareLinkSubmissionStepMutation,
  useGetShareLinkFormWorkflowQuery,
  useGetShareLinkSubmissionPrerequisitesQuery,
} = submissionShareLinkApi;
