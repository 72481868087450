import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  // Task related dialogs
  createTaskDialog: false,
  editTaskDialog: false,
  deleteTaskDialog: false,
  duplicateTaskDialog: false,
  taskDialog: false,

  // Subtask related dialogs
  createSubTaskDialog: false,
  editSubTaskDialog: false,
  subTaskDialog: false,

  // Equipment related dialogs
  createEquipmentDialog: false,
  updateEquipmentDialog: false,
  deleteEquipmentDialog: false,
  equipmentLocationDialog: false,

  // Equipment attendance related dialogs
  updateEquipmentAttendanceRecordDialog: false,
  deleteEquipmentAttendanceRecordDialog: false,

  // Timesheet related dialogs
  createTimesheetRecordDialog: false,
  updateTimesheetRecordDialog: false,
  deleteTimesheetRecordDialog: false,
  createTimeOffDialog: false,
  updateTimeOffDialog: false,
  createRecordDialog: false,
  repliedAttendanceRecordRequestDialog: false,

  // Team related dialogs
  createNewTeamDialog: false,
  updateTeamDialog: false,
  deleteTeamDialog: false,
  teamSettingDialog: false,

  // Member related dialogs
  createAssignToMemberDialog: false,
  createInviteMemberDialog: false,
  inviteUserToProjectsDialog: false,
  deleteUserFromGroupDialog: false,
  deleteUserFromProjectsDialog: false,

  // Manpower related dialogs
  updateManpowerDialog: false,
  deleteManpowerDialog: false,

  // report related dialogs
  deleteViewDialog: false,
  deleteTemplateDialog: false,
  duplicateTemplateDialog: false,

  // Form template related dialogs
  createFolderDialog: false,
  createTemplateDialog: false,
  updateFolderDialog: false,
  updateTemplateDialog: false,
  moveToTrashDialog: false,
  deleteForeverDialog: false,
  chooseFolderDialog: false,
  restoreItemDialog: false,
  fillSubFormDialog: false,

  // project form submission dialogs
  assignTemplateToSubmissionDialog: false,
  createProjectFormFolderDialog: false,
  chooseSubmissionTemplateFolderDialog: false,
  chooseDestinationProjectSubmissionTemplateFolderDialog: false,
  chooseTemplatesDialog: false,
  renameFolderDialog: false,
  duplicateToProjectDialog: false,
  moveToTrashWithSubmissionDialog: false,
  createSubmissionDialog: false,
  moveSubmissionTemplateDialog: false,
  moveSubmissionToTrashDialog: false,
  copyStepDialog: false,
  shareStepDialog: false,
  chooseSubFormsDialog: false,
  deleteShareLinkDialog: false,

  // form collection related dialogs
  formCollectionDeleteConfirmDialog: false,

  // form task related dialogs
  createNewSubmissionDialog: false,
  selectFromTemplateDialog: false,
  formTaskCreateSubmissionDialog: false,
  submissionMoveToTrashDialog: false,

  // Other dialogs
  printQRCodeDialog: false,
  projectArchivedOrDeleteDialog: false,

  // web
  comparePlansDialog: false,
};

export type DialogName = keyof typeof initialState;

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<DialogName>) => {
      state[action.payload] = true;
    },
    closeDialog: (state, action: PayloadAction<DialogName>) => {
      state[action.payload] = false;
    },
    toggleDialog: (state, action: PayloadAction<DialogName>) => {
      state[action.payload] = !state[action.payload];
    },
  },
});

export const { openDialog, closeDialog, toggleDialog } = dialogsSlice.actions;

export default dialogsSlice.reducer;
